import type {
  njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportResponseSchema,
  Account2,
  HoldingsReportOrganization,
  Institution,
  njorda_bps_b2b_v1_schemas_core_UrlResponseSchema,
  HoldingsReportUser,
  PositionDetails,
  DataSessionConnection,
} from "@/api/njorda";

export type { DataProvider as TPortfolioSharingProvider } from "@/api/njorda";

export type TSelectedProviders = {
  [key: string]: boolean;
};

export type TUserPortfolioAccount = Account2 & {
  is_manual_account?: boolean;
};
export type TUserPortfolioPosition = PositionDetails;

export type TUserPortfolioAccountWithPositions = TUserPortfolioAccount & {
  positions?: TUserPortfolioPosition[];
};

export type TDataSessionConnectionWithStatuses = DataSessionConnection & {
  isLoading: boolean;
  isDone: boolean;
  isFailed: boolean;
  isInUnknownStatus: boolean;
};

export type TUserPortfolioAccountInstitution = Institution & {
  accounts: TUserPortfolioAccountWithPositions[];
  identifier?: string;
  id?: string;
  isManualAccount?: boolean;
  connection?: TDataSessionConnectionWithStatuses;
};

export type TLoanOverview = {
  institution?: string | null;
  name: string;
  amount: number;
  rate: string;
};

export enum TPortfolioPredictionKeys {
  worst,
  best,
  expected,
}

export type TPortfolioPredictionValue = {
  amount: number;
  currency: string;
};

export type TPortfolioPrediction = {
  title?: string;
  percents: string;
  value: TPortfolioPredictionValue;
  style: string;
};

export type TPortfolioPredictions = {
  [key in TPortfolioPredictionKeys as string]?: TPortfolioPrediction;
};

export type TPortfolioReportDetails = njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportResponseSchema;
export type TPortfolioReportOrganization = HoldingsReportOrganization;
export type TPortfolioReportUser = HoldingsReportUser;

export type TPortfolioCollectionUrl = njorda_bps_b2b_v1_schemas_core_UrlResponseSchema;

export const DATA_SESSION_CONNECTION_STATUS = {
  AUTH_WAITING: "auth_waiting",
  AUTH_STARTED: "auth_started",
  AUTH_ERROR: "auth_error",
  AUTH_SUCCESS: "auth_success",
  DATA_COLLECTING: "data_collecting",
  DATA_COLLECTING_ERROR: "data_collecting_error",
  DATA_COLLECTING_SUCCESS: "data_collecting_success",
  DATA_PROCESSING: "data_processing",
  DATA_PROCESSING_ERROR: "data_processing_error",
  DATA_PROCESSING_SUCCESS: "data_processing_success",
};
